import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import replaceS3WithCDN from 'utils/replaceS3WithCDN';
import { withTranslation } from 'react-i18next';
import { compose } from 'recompose';

import PageSectionHeaders from 'components/PageSectionHeaders/Loadable';
import CityCard from 'components/CityCard/Loadable';
import SepareationLine from 'components/SeparationLine/SeparationLine';

/* -------------------------------------------------------------------------- */
/*                              styled-components                             */
/* -------------------------------------------------------------------------- */
const MobileScrollableWrap = styled.div`
  display: flex;
  margin-top: 0px;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -24px;
  margin-right: 0;
  transition-duration: 0.5s, 0.5s, 0.5s;
  transition-timing-function: initial, initial, initial;
  transition-delay: initial, initial, initial;
  transition-property: -ms-transform, transform, transform;
  white-space: nowrap;
  overflow-x: auto;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch !important;
`;

const MobileScrollableItem = styled.div`
  flex: 1 0 0;
  max-width: 278px;
  padding: 0 8px;
  display: inline-block;
  white-space: normal;
  vertical-align: top;
  margin: 0 0 8px;
`;

// eslint-disable-next-line react/prop-types
const Cities = memo(({ cities = [], country: countryName }) => (
  <MobileScrollableWrap>
    {
      cities.map((city, index) => {
        const {
          cover_photo_thumbnail_url: photo,
          name,
          country,
          overall_rating: overallRating,
        } = city;
        const citiesCountryName = country?.name;
        const currency = country?.currency;

        // filter countryName if it is set
        if (countryName && citiesCountryName !== countryName) return null;

        const data = {
          name,
          photos: [replaceS3WithCDN(photo)],
          currency: currency.toUpperCase(),
          overallRating
        };

        return (
          <MobileScrollableItem key={`city-${index}`}>
            <Link to={`/search/${name}`}>
              <CityCard
                city={data}
              />
            </Link>
          </MobileScrollableItem>
        );
      })
    }
  </MobileScrollableWrap>
));

const NotFound = ({ cities, t }) => {
  const styles = require('./NotFound.scss');
  const notFound404 = require('./assets/404.svg');

  return (
    <div className={`container ${styles.root}`}>
      <PageSectionHeaders
        title="Page Not Found"
        subtitle="Sorry, the page you're looking for cannot be accessed"
        center
        alwaysCenter
      />
      <picture className={styles.notFoundSvg}>
        <source srcSet={notFound404} />
        <img src={notFound404} alt="404 Not Found" />
      </picture>
      <p className={styles.paragraph}>
        {'Either check the URL or go to '}
        <Link to="/">
          Home Page
        </Link>
      </p>

      <SepareationLine />

      <PageSectionHeaders
        title={t('HOME.DESTINATIONS.HEADING')}
      />
      <Cities cities={cities} />
    </div>
  );
};

NotFound.propTypes = {
  history: PropTypes.objectOf(PropTypes.any),
  cities: PropTypes.objectOf(PropTypes.any),
  t: PropTypes.func,
};

NotFound.defaultProps = {
  history: {},
  cities: [],
  t: () => {},
};

export default compose(
  connect(
    state => ({
      cities: state.cities?.cities,
    }),
  ),
  withRouter,
  withTranslation(['new-version'])
)(NotFound);
